import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import SnowLayout from '../components/SnowLayout';
import Seo from '../components/Seo';
import '../styles/global.css';

export default function SucessPage() {
   return (
      <SnowLayout>
         <Seo title='Message Received' />
         <div className='bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 lg:px-8 flex flex-col items-center justify-center'>
            <StaticImage src='../images/hunter-environmental-logo-blue-fill.svg' alt='Hunter Environmental Logo' />
            <div className='max-w-max mx-auto mt-24'>
               <main className='flex items-center justify-center text-center max-w-md'>
                  <div>
                     <div>
                        <h1 className='text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>Thank you for getting in touch! </h1>
                        <p className='mt-1 text-base text-gray-500'>
                           We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!
                        </p>
                     </div>
                     <div className='mt-10 flex items-center justify-center space-x-3 '>
                        <Link
                           href='/'
                           className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-snow hover:bg-snow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-snow'
                        >
                           Go back
                        </Link>
                     </div>
                  </div>
               </main>
            </div>
         </div>
      </SnowLayout>
   );
}
